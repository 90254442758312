import React from "react"


//Import de composants
import LegalLayout from "../../components/legals/LegalLayout"

import Article from "../../components/legals/ArticleMaker"
import Summary from "../../components/legals/Summary"

//Import de fichiers
import cgs from '../../utils/Legals/cgs.json'

import { Link } from "gatsby"




/**
 *
 * @returns Le corps principal de la page
 * @constructor
 */
export default function Cgu({location}){
  return(

    <LegalLayout activeLink={location.pathname}>

      <div className="cgs">
        <span className="version">Version en date du 01/06/2020</span>
        <div className="cgs__head"><h1>Conditions générales de ventes et d'utilisations</h1></div>
        <p className="opening-text">
          Les présentes ont pour objet de définir les conditions de mise à disposition et d’utilisation des Services de la SARL
          GetCaaS.<br/>
          Elles forment, avec les Conditions Particulières de Service applicables et l’ensemble des documents auxquels elles
          font référence, un contrat (ci-après le « Contrat ») conclu entre SARL GETCAAS, 12 rue Albert Einstein - 48000
          Mende - France - SAS au capital de 100 € RCS MENDE - SIRET : 88116347100017 - R.C.S. : 881163471 - TVA:
          FR59881163471 (ci-après dénommée (« GETCAAS »), et toute personne physique ou morale, consommateur ou
          professionnel, de droit privé ou de droit public, créant un Compte Client sur le Site Internet <Link to="http://www.getcaas.io">www.getcaas.io</Link> (ci-après
          nommée le « Client ») ; le Client et GETCAAS étant individuellement ou conjointement dénommés la ou les «
          Partie(s) ».<br/>
          Les termes commençant par une majuscule ont le sens défini dans le cadre des présentes Conditions Générales de
          Service, ou, à défaut, dans le <Link to="/Legals/glossaire">Glossaire GETCAAS</Link>, accessible sur le Site <a href="http://www.getcaas.io">www.getcaas.io.</a>
        </p>
        {
          cgs.fr.map((art) => {
            return <Article key={art._id} article={art} prefixClass="" />
          })
        }
      </div>
      <Summary tabTitle={Array.from(cgs.fr, (el) => {return [el.ancre, el._id]}) }/>
    </LegalLayout>
  )
}